<template>
  <div class="container_margin">
    <b-modal id="modal-center-add-employee" centered hide-footer hide-header>
      <div class="card-body">
        <strong class="card-title"
          >{{ "Add" }} {{ "New" }} {{ "Employee" }}</strong
        >

        <div class="card-text mt-3">
          <label for="fname">{{ "Name" }}</label>
        </div>

        <div class="card-text mt-2">
          <b-form-input
            autocorrect="off"
            autocomplete="off"
            v-model="name"
            type="text"
            id="name"
            name="name"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>

        <div class="card-text mt-3">
          <label for="fname">{{ "Email" }}</label>
        </div>

        <div class="card-text mt-2">
          <b-form-input
            autocorrect="off"
            autocomplete="off"
            type="text"
            id="email"
            name="email"
            v-model="email"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{
            errors.first("email")
          }}</span>
        </div>

        <div class="card-text mt-3">
          <label for="fname">{{ "Password" }} </label>
        </div>

        <div class="card-text mt-2">
          <b-form-input
            autocorrect="off"
            autocomplete="off"
            v-model="password"
            type="password"
            id="password"
            name="password"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('password')">{{
            errors.first("password")
          }}</span>
        </div>

        <div class="card-text d-flex mt-3">
          <button
            class="btn btn-primary-outlined ms-auto"
            @click="$bvModal.hide('modal-center-add-employee')"
          >
            {{ "Cancel" }}
          </button>
          <button @click="addEmployee" class="btn btn-primary ms-2">
            {{ "Add" }}
          </button>
        </div>
      </div>
    </b-modal>

    <div class="row">
      <div class="col">
        <div class="card border shadow-sm p-3 mt-3 rounded">
          <div class="row">
            <div class="col">
              <h5 class="font-weight-bold align-self-center">Employees</h5>
            </div>
            <div class="col-12 col-md-3 col-lg-4 col-xl-2">
              <button
                v-b-modal.modal-center-add-employee
                class="float-right btn btn-primary w-100 align-self-center"
              >
                Add Employees
              </button>
            </div>
          </div>

          <div class="row mt-4 table-card">
            <div class="col">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" width="10%">{{ "Status" }}</th>
                    <th scope="col" width="20%">{{ "Full Name" }}</th>
                    <th scope="col" width="20%">{{ "Email" }}</th>
                    <th scope="col" width="20%">{{ "Role" }}</th>
                    <th scope="col" width="20%">{{ "Policy" }}</th>
                    <th scope="col" width="10%">{{ "Action" }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in employees_list"
                    :key="'ner_list' + index"
                  >
                    <!--   <td class="py-4">
                    <p class="font-weight-bold text-center text-muted"> Start Your Case Search 

                    </p>
                </td> -->
                    <td width="10%">
                      {{ item.status }}
                    </td>
                    <td width="20%">
                      {{ item.name }}
                    </td>
                    <td width="20%">
                      {{ item.email }}
                    </td>
                    <td width="20%">
                      {{ item.role }}
                    </td>
                    <td width="20%">
                      {{ item.policy }}
                    </td>
                    <td width="10%">
                      <button @click="editEmployee" class="btn btn-primary w-100">Edit</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  data() {
    return {
      employees_list: [
        {
          status: "Active",
          name: "John Doe",
          email: "john@example.com",
          role: "Developer",
          policy: "Standard",
        },
        // Add more employee objects as needed
      ],
      name: "",
      email: "",
      password: "",
    };
  },
  methods: {
    addEmployee() {
      // Implement your logic to add an employee
      this.$bvModal.hide("modal-center-add-employee");
    },
    editEmployee(index) {
      // Implement your logic to edit an employee
      this.$router.push({
        name: "employee",
      });
    },
  },
};
</script>






<style scoped>
.container_margin {
  padding: 2rem;
}
</style>